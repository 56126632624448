<template>
    <div class="option_catalogue">
        <vs-row>
            <vs-col vs-xs="12" vs-lg="12">
                <vx-card>
                    <h3>Information établissement</h3>
                    <hr/>
                    <br/>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Type" v-model="studio.society" />
                    </div>
                     <div class="vx-col w-full">
                        <vs-input class="w-full" label="Nom societé" v-model="studio.type" />
                    </div>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Capital" v-model="studio.capital" type="number" />
                    </div>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Adresse" v-model="studio.address" />
                    </div>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Ville" v-model="studio.city" />
                    </div>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Code postal" v-model="studio.code_postal" type="number" />
                    </div>
                </vx-card>

                <br/>
                <vx-card>
                    <h3>Contact public</h3>
                    <hr/>
                    <br/>
                    <div class="vx-col w-full">
                        <br/>
                        <vue-tel-input class="w-full" label="Téléphone" v-model="studio.phone"></vue-tel-input>
                        <br/>
                    </div>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Email" v-model="studio.email" />
                    </div>
                </vx-card>

                <br/>
                <vx-card>
                    <h3>Numéro société</h3>
                    <hr/>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="SIREN" v-model="studio.siren" />
                    </div>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="RCS ville" v-model="studio.rcs_city" />
                    </div>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="TVA numéro" v-model="studio.number_tva" />
                    </div>
                </vx-card>

                <br/>
                <vx-card>
                    <h3>Mollie Clef API</h3>
                    <hr/>
                    <br/>
                    

                    <vs-button style="background:#1c0f2a; " type="filled"><a href="https://www.mollie.com/dashboard/signup/7473411?lang=fr" style="color:white;">Créer un compte Mollie</a></vs-button>
                    <br/>
                    <br/>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" type="password" label="Mollie.com > Dashboard > Développeurs > Clés API" v-model="studio.mollie" />
                    </div>
                </vx-card>

            </vs-col>
        </vs-row>

        <!--<vs-divider/>-->
        <br/>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <div></div>
                <vs-button color="success" type="filled" v-on:click="update()"> Modifier </vs-button>
            </vs-col>
        </vs-row>
        
    </div>
</template>



<style lang="scss">
.option_catalogue
{

}
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'

import Studio from '@/database/models/studio'

export default {
    data(){
        return{
            studio:{
                type        : '',
                society     : '',
                address     : '',
                city        : '',
                code_postal : '',
                country     : '',
                capital     : '',
                siren       : '',
                rcs_city    : '',
                number_tva  : '',
                phone       : '',
                email       : '',
                mollie      : '',
            }
        }
	},
	mounted:function()
	{
        //autoRefresh
        autoRefresh.SetCallback(()=>
        {
            //refresh
            Studio.getAllMemory((list_studio)=>
            {
                //find actual studio
                this.studio = JSON.parse(JSON.stringify( list_studio[ this.$srvApi.getStudio() ] ));
            })
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            
        }
    },
    methods:{
		update(){
            //nettoyage vide
            let data = {}
            for( var i in this.studio)
            if( this.studio[i])
                data[i] = this.studio[i]

            //envoi mise a jour
            Studio.update( this.$srvApi.getStudio(), data )
            .then(()=>{
                alert('Modification enregistrée')
            })
            .catch(()=>{
                alert('Une erreur est survenue !')
            })
        },
    }
};

</script>